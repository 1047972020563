/* tslint:disable */
/* eslint-disable */
/**
 * Theia.cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * An object to hold all the ways environment variables can be passed. Not to be used by itself.
 * @export
 * @interface EnvironmentVars
 */
export interface EnvironmentVars {
    /**
     * Map of environment variables to be passed to Deployment.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {{ [key: string]: string; }}
     * @memberof EnvironmentVars
     */
    'fromMap'?: { [key: string]: string; };
    /**
     * List of ConfigMaps (by name) containing environment variables to be passed to Deployment as envFrom.configMapRef.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {Array<string>}
     * @memberof EnvironmentVars
     */
    'fromConfigMaps'?: Array<string>;
    /**
     * List of Secrets (by name) containing environment variables to be passed to Deployment as envFrom.secretRef.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {Array<string>}
     * @memberof EnvironmentVars
     */
    'fromSecrets'?: Array<string>;
}
/**
 * A request to launch a new session.
 * @export
 * @interface LaunchRequest
 */
export interface LaunchRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof LaunchRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof LaunchRequest
     */
    'user': string;
    /**
     * The app to launch. Needs to be set if a new or ephemeral session should be launched. For an existing workspace the last app definition will be used if none is given.
     * @type {string}
     * @memberof LaunchRequest
     */
    'appDefinition'?: string;
    /**
     * The name of the workspace to mount/create. Needs to be set if an existing workspace should be launched.
     * @type {string}
     * @memberof LaunchRequest
     */
    'workspaceName'?: string;
    /**
     * The label of the workspace to mount/create. If no label is given, a default label will be generated.
     * @type {string}
     * @memberof LaunchRequest
     */
    'label'?: string;
    /**
     * If true no workspace will be created for the session.
     * @type {boolean}
     * @memberof LaunchRequest
     */
    'ephemeral'?: boolean;
    /**
     * Number of minutes to wait for session launch. Default is 3 Minutes.
     * @type {number}
     * @memberof LaunchRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {LaunchRequestEnv}
     * @memberof LaunchRequest
     */
    'env'?: LaunchRequestEnv;
}
/**
 * 
 * @export
 * @interface LaunchRequestEnv
 */
export interface LaunchRequestEnv {
    /**
     * Map of environment variables to be passed to Deployment.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {{ [key: string]: string; }}
     * @memberof LaunchRequestEnv
     */
    'fromMap'?: { [key: string]: string; };
    /**
     * List of ConfigMaps (by name) containing environment variables to be passed to Deployment as envFrom.configMapRef.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {Array<string>}
     * @memberof LaunchRequestEnv
     */
    'fromConfigMaps'?: Array<string>;
    /**
     * List of Secrets (by name) containing environment variables to be passed to Deployment as envFrom.secretRef.  Ignored if Theia applications are started eagerly.  Empty by default.
     * @type {Array<string>}
     * @memberof LaunchRequestEnv
     */
    'fromSecrets'?: Array<string>;
}
/**
 * Request to ping the availability of the service.
 * @export
 * @interface PingRequest
 */
export interface PingRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof PingRequest
     */
    'appId': string;
}
/**
 * A request to report activity for a running session.
 * @export
 * @interface SessionActivityRequest
 */
export interface SessionActivityRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof SessionActivityRequest
     */
    'appId': string;
    /**
     * The name of the session for which activity is reported.
     * @type {string}
     * @memberof SessionActivityRequest
     */
    'sessionName': string;
}
/**
 * A request to list the sessions of a user.
 * @export
 * @interface SessionListRequest
 */
export interface SessionListRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof SessionListRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof SessionListRequest
     */
    'user': string;
}
/**
 * Description of the performance of a session
 * @export
 * @interface SessionPerformance
 */
export interface SessionPerformance {
    /**
     * Used CPU amount of the workspace
     * @type {string}
     * @memberof SessionPerformance
     */
    'cpuAmount': string;
    /**
     * Used CPU format of the workspace
     * @type {string}
     * @memberof SessionPerformance
     */
    'cpuFormat': string;
    /**
     * Used memory amount of the workspace
     * @type {string}
     * @memberof SessionPerformance
     */
    'memoryAmount': string;
    /**
     * Used memory format of the workspace
     * @type {string}
     * @memberof SessionPerformance
     */
    'memoryFormat': string;
}
/**
 * A request to list the sessions of a user.
 * @export
 * @interface SessionPerformanceRequest
 */
export interface SessionPerformanceRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof SessionPerformanceRequest
     */
    'appId': string;
    /**
     * The name of the session
     * @type {string}
     * @memberof SessionPerformanceRequest
     */
    'sessionName': string;
}
/**
 * 
 * @export
 * @interface SessionSpec
 */
export interface SessionSpec {
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'appDefinition'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'workspace'?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionSpec
     */
    'lastActivity'?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionSpec
     */
    'sessionSecret'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SessionSpec
     */
    'envVars'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionSpec
     */
    'envVarsFromConfigMaps'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionSpec
     */
    'envVarsFromSecrets'?: Array<string>;
}
/**
 * A request to start a session
 * @export
 * @interface SessionStartRequest
 */
export interface SessionStartRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof SessionStartRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof SessionStartRequest
     */
    'user': string;
    /**
     * The app to launch.
     * @type {string}
     * @memberof SessionStartRequest
     */
    'appDefinition': string;
    /**
     * The name of the workspace to mount/create.
     * @type {string}
     * @memberof SessionStartRequest
     */
    'workspaceName'?: string;
    /**
     * Number of minutes to wait for session launch. Default is 3 Minutes.
     * @type {number}
     * @memberof SessionStartRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {LaunchRequestEnv}
     * @memberof SessionStartRequest
     */
    'env'?: LaunchRequestEnv;
}
/**
 * A request to stop a session
 * @export
 * @interface SessionStopRequest
 */
export interface SessionStopRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof SessionStopRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof SessionStopRequest
     */
    'user': string;
    /**
     * The name of the session to stop.
     * @type {string}
     * @memberof SessionStopRequest
     */
    'sessionName': string;
}
/**
 * Description of a user workspace
 * @export
 * @interface UserWorkspace
 */
export interface UserWorkspace {
    /**
     * The name of the workspace
     * @type {string}
     * @memberof UserWorkspace
     */
    'name': string;
    /**
     * The label of the workspace
     * @type {string}
     * @memberof UserWorkspace
     */
    'label': string;
    /**
     * The app this workspace was used with.
     * @type {string}
     * @memberof UserWorkspace
     */
    'appDefinition'?: string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof UserWorkspace
     */
    'user': string;
    /**
     * Whether the workspace is in use at the moment.
     * @type {boolean}
     * @memberof UserWorkspace
     */
    'active': boolean;
}
/**
 * Request to create a new workspace.
 * @export
 * @interface WorkspaceCreationRequest
 */
export interface WorkspaceCreationRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof WorkspaceCreationRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof WorkspaceCreationRequest
     */
    'user': string;
    /**
     * The app this workspace will be used with.
     * @type {string}
     * @memberof WorkspaceCreationRequest
     */
    'appDefinition'?: string;
    /**
     * The label of the workspace
     * @type {string}
     * @memberof WorkspaceCreationRequest
     */
    'label'?: string;
}
/**
 * Request to delete a workspace
 * @export
 * @interface WorkspaceDeletionRequest
 */
export interface WorkspaceDeletionRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof WorkspaceDeletionRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof WorkspaceDeletionRequest
     */
    'user': string;
    /**
     * The name of the workspace to delete.
     * @type {string}
     * @memberof WorkspaceDeletionRequest
     */
    'workspaceName': string;
}
/**
 * Request to list workspaces of a user.
 * @export
 * @interface WorkspaceListRequest
 */
export interface WorkspaceListRequest {
    /**
     * The App Id of this Theia.cloud instance. Request without a matching Id will be denied.
     * @type {string}
     * @memberof WorkspaceListRequest
     */
    'appId': string;
    /**
     * The user identification, usually the email address.
     * @type {string}
     * @memberof WorkspaceListRequest
     */
    'user': string;
}

/**
 * RootResourceApi - axios parameter creator
 * @export
 */
export const RootResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replies if the service is available.
         * @summary Ping
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAppIdGet: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('serviceAppIdGet', 'appId', appId)
            const localVarPath = `/service/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Launches a session and creates a workspace if required. Responds with the URL of the launched session.
         * @summary Launch Session
         * @param {LaunchRequest} [launchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicePost: async (launchRequest?: LaunchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(launchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootResourceApi - functional programming interface
 * @export
 */
export const RootResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RootResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Replies if the service is available.
         * @summary Ping
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAppIdGet(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAppIdGet(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Launches a session and creates a workspace if required. Responds with the URL of the launched session.
         * @summary Launch Session
         * @param {LaunchRequest} [launchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicePost(launchRequest?: LaunchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicePost(launchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RootResourceApi - factory interface
 * @export
 */
export const RootResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RootResourceApiFp(configuration)
    return {
        /**
         * Replies if the service is available.
         * @summary Ping
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAppIdGet(appId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.serviceAppIdGet(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Launches a session and creates a workspace if required. Responds with the URL of the launched session.
         * @summary Launch Session
         * @param {LaunchRequest} [launchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicePost(launchRequest?: LaunchRequest, options?: any): AxiosPromise<string> {
            return localVarFp.servicePost(launchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RootResourceApi - object-oriented interface
 * @export
 * @class RootResourceApi
 * @extends {BaseAPI}
 */
export class RootResourceApi extends BaseAPI {
    /**
     * Replies if the service is available.
     * @summary Ping
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootResourceApi
     */
    public serviceAppIdGet(appId: string, options?: AxiosRequestConfig) {
        return RootResourceApiFp(this.configuration).serviceAppIdGet(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Launches a session and creates a workspace if required. Responds with the URL of the launched session.
     * @summary Launch Session
     * @param {LaunchRequest} [launchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootResourceApi
     */
    public servicePost(launchRequest?: LaunchRequest, options?: AxiosRequestConfig) {
        return RootResourceApiFp(this.configuration).servicePost(launchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionResourceApi - axios parameter creator
 * @export
 */
export const SessionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List sessions of a user.
         * @summary List sessions
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionAppIdUserGet: async (appId: string, user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('serviceSessionAppIdUserGet', 'appId', appId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('serviceSessionAppIdUserGet', 'user', user)
            const localVarPath = `/service/session/{appId}/{user}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops a session.
         * @summary Stop session
         * @param {SessionStopRequest} [sessionStopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionDelete: async (sessionStopRequest?: SessionStopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionStopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the last activity timestamp for a session to monitor activity.
         * @summary Report session activity
         * @param {SessionActivityRequest} [sessionActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPatch: async (sessionActivityRequest?: SessionActivityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionActivityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current CPU and memory usage of the session\'s pod.
         * @summary Get performance metrics
         * @param {string} appId 
         * @param {string} sessionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPerformanceAppIdSessionNameGet: async (appId: string, sessionName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('serviceSessionPerformanceAppIdSessionNameGet', 'appId', appId)
            // verify required parameter 'sessionName' is not null or undefined
            assertParamExists('serviceSessionPerformanceAppIdSessionNameGet', 'sessionName', sessionName)
            const localVarPath = `/service/session/performance/{appId}/{sessionName}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"sessionName"}}`, encodeURIComponent(String(sessionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a new session for an existing workspace and responds with the URL of the started session.
         * @summary Start a new session
         * @param {SessionStartRequest} [sessionStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPost: async (sessionStartRequest?: SessionStartRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionStartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionResourceApi - functional programming interface
 * @export
 */
export const SessionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * List sessions of a user.
         * @summary List sessions
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSessionAppIdUserGet(appId: string, user: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionSpec>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSessionAppIdUserGet(appId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops a session.
         * @summary Stop session
         * @param {SessionStopRequest} [sessionStopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSessionDelete(sessionStopRequest?: SessionStopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSessionDelete(sessionStopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the last activity timestamp for a session to monitor activity.
         * @summary Report session activity
         * @param {SessionActivityRequest} [sessionActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSessionPatch(sessionActivityRequest?: SessionActivityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSessionPatch(sessionActivityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current CPU and memory usage of the session\'s pod.
         * @summary Get performance metrics
         * @param {string} appId 
         * @param {string} sessionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSessionPerformanceAppIdSessionNameGet(appId: string, sessionName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionPerformance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSessionPerformanceAppIdSessionNameGet(appId, sessionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts a new session for an existing workspace and responds with the URL of the started session.
         * @summary Start a new session
         * @param {SessionStartRequest} [sessionStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSessionPost(sessionStartRequest?: SessionStartRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSessionPost(sessionStartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionResourceApi - factory interface
 * @export
 */
export const SessionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionResourceApiFp(configuration)
    return {
        /**
         * List sessions of a user.
         * @summary List sessions
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionAppIdUserGet(appId: string, user: string, options?: any): AxiosPromise<Array<SessionSpec>> {
            return localVarFp.serviceSessionAppIdUserGet(appId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops a session.
         * @summary Stop session
         * @param {SessionStopRequest} [sessionStopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionDelete(sessionStopRequest?: SessionStopRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.serviceSessionDelete(sessionStopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the last activity timestamp for a session to monitor activity.
         * @summary Report session activity
         * @param {SessionActivityRequest} [sessionActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPatch(sessionActivityRequest?: SessionActivityRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.serviceSessionPatch(sessionActivityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current CPU and memory usage of the session\'s pod.
         * @summary Get performance metrics
         * @param {string} appId 
         * @param {string} sessionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPerformanceAppIdSessionNameGet(appId: string, sessionName: string, options?: any): AxiosPromise<SessionPerformance> {
            return localVarFp.serviceSessionPerformanceAppIdSessionNameGet(appId, sessionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a new session for an existing workspace and responds with the URL of the started session.
         * @summary Start a new session
         * @param {SessionStartRequest} [sessionStartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSessionPost(sessionStartRequest?: SessionStartRequest, options?: any): AxiosPromise<string> {
            return localVarFp.serviceSessionPost(sessionStartRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionResourceApi - object-oriented interface
 * @export
 * @class SessionResourceApi
 * @extends {BaseAPI}
 */
export class SessionResourceApi extends BaseAPI {
    /**
     * List sessions of a user.
     * @summary List sessions
     * @param {string} appId 
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionResourceApi
     */
    public serviceSessionAppIdUserGet(appId: string, user: string, options?: AxiosRequestConfig) {
        return SessionResourceApiFp(this.configuration).serviceSessionAppIdUserGet(appId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops a session.
     * @summary Stop session
     * @param {SessionStopRequest} [sessionStopRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionResourceApi
     */
    public serviceSessionDelete(sessionStopRequest?: SessionStopRequest, options?: AxiosRequestConfig) {
        return SessionResourceApiFp(this.configuration).serviceSessionDelete(sessionStopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the last activity timestamp for a session to monitor activity.
     * @summary Report session activity
     * @param {SessionActivityRequest} [sessionActivityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionResourceApi
     */
    public serviceSessionPatch(sessionActivityRequest?: SessionActivityRequest, options?: AxiosRequestConfig) {
        return SessionResourceApiFp(this.configuration).serviceSessionPatch(sessionActivityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current CPU and memory usage of the session\'s pod.
     * @summary Get performance metrics
     * @param {string} appId 
     * @param {string} sessionName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionResourceApi
     */
    public serviceSessionPerformanceAppIdSessionNameGet(appId: string, sessionName: string, options?: AxiosRequestConfig) {
        return SessionResourceApiFp(this.configuration).serviceSessionPerformanceAppIdSessionNameGet(appId, sessionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a new session for an existing workspace and responds with the URL of the started session.
     * @summary Start a new session
     * @param {SessionStartRequest} [sessionStartRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionResourceApi
     */
    public serviceSessionPost(sessionStartRequest?: SessionStartRequest, options?: AxiosRequestConfig) {
        return SessionResourceApiFp(this.configuration).serviceSessionPost(sessionStartRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceResourceApi - axios parameter creator
 * @export
 */
export const WorkspaceResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists the workspaces of a user.
         * @summary List workspaces
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspaceAppIdUserGet: async (appId: string, user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('serviceWorkspaceAppIdUserGet', 'appId', appId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('serviceWorkspaceAppIdUserGet', 'user', user)
            const localVarPath = `/service/workspace/{appId}/{user}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a workspace.
         * @summary Delete workspace
         * @param {WorkspaceDeletionRequest} [workspaceDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspaceDelete: async (workspaceDeletionRequest?: WorkspaceDeletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceDeletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new workspace for a user.
         * @summary Create workspace
         * @param {WorkspaceCreationRequest} [workspaceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspacePost: async (workspaceCreationRequest?: WorkspaceCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceResourceApi - functional programming interface
 * @export
 */
export const WorkspaceResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists the workspaces of a user.
         * @summary List workspaces
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceWorkspaceAppIdUserGet(appId: string, user: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWorkspace>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceWorkspaceAppIdUserGet(appId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a workspace.
         * @summary Delete workspace
         * @param {WorkspaceDeletionRequest} [workspaceDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceWorkspaceDelete(workspaceDeletionRequest?: WorkspaceDeletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceWorkspaceDelete(workspaceDeletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new workspace for a user.
         * @summary Create workspace
         * @param {WorkspaceCreationRequest} [workspaceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceWorkspacePost(workspaceCreationRequest?: WorkspaceCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceWorkspacePost(workspaceCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceResourceApi - factory interface
 * @export
 */
export const WorkspaceResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceResourceApiFp(configuration)
    return {
        /**
         * Lists the workspaces of a user.
         * @summary List workspaces
         * @param {string} appId 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspaceAppIdUserGet(appId: string, user: string, options?: any): AxiosPromise<Array<UserWorkspace>> {
            return localVarFp.serviceWorkspaceAppIdUserGet(appId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a workspace.
         * @summary Delete workspace
         * @param {WorkspaceDeletionRequest} [workspaceDeletionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspaceDelete(workspaceDeletionRequest?: WorkspaceDeletionRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.serviceWorkspaceDelete(workspaceDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new workspace for a user.
         * @summary Create workspace
         * @param {WorkspaceCreationRequest} [workspaceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceWorkspacePost(workspaceCreationRequest?: WorkspaceCreationRequest, options?: any): AxiosPromise<UserWorkspace> {
            return localVarFp.serviceWorkspacePost(workspaceCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceResourceApi - object-oriented interface
 * @export
 * @class WorkspaceResourceApi
 * @extends {BaseAPI}
 */
export class WorkspaceResourceApi extends BaseAPI {
    /**
     * Lists the workspaces of a user.
     * @summary List workspaces
     * @param {string} appId 
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceResourceApi
     */
    public serviceWorkspaceAppIdUserGet(appId: string, user: string, options?: AxiosRequestConfig) {
        return WorkspaceResourceApiFp(this.configuration).serviceWorkspaceAppIdUserGet(appId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a workspace.
     * @summary Delete workspace
     * @param {WorkspaceDeletionRequest} [workspaceDeletionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceResourceApi
     */
    public serviceWorkspaceDelete(workspaceDeletionRequest?: WorkspaceDeletionRequest, options?: AxiosRequestConfig) {
        return WorkspaceResourceApiFp(this.configuration).serviceWorkspaceDelete(workspaceDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new workspace for a user.
     * @summary Create workspace
     * @param {WorkspaceCreationRequest} [workspaceCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceResourceApi
     */
    public serviceWorkspacePost(workspaceCreationRequest?: WorkspaceCreationRequest, options?: AxiosRequestConfig) {
        return WorkspaceResourceApiFp(this.configuration).serviceWorkspacePost(workspaceCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


